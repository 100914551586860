import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Team Messaging Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/team-messaging-web-app/"
    metaDescription="Looking to design your own team messaging website? Our brand-new team messaging website design template is here to help. Try it with Uizard Pro now!"
    description="
    h2:Say hello to our new team messaging website design template
    <br/>
    Whether you are new to design or you consider yourself a seasoned project manager, our <a:https://uizard.io/templates/>UI templates</a> are the perfect way to create website designs faster than ever before. Our brand-new team messaging website design template was created by our team of expert designers and contains everything you could need for your project!
    <br/>
    h3:Work faster; customize easier; bring your idea to life
    <br/>
    Our easy-to-use editor is here to make customization easier than ever before. Say goodbye to complicated vector design with drag-and-drop <a:https://uizard.io/prototyping/>UX/UI prototyping</a>. Add new screens, update user journeys, or swap out the entire style of your messaging website design with the click of a button… the possibilities are endless.
    <br/>
    h3:Share ideas and streamline project sign-off
    <br/>
    Want to get your project signed off fast? Uizard's smart collaboration features make it easier than ever to share ideas, work collaboratively, collect feedback, and get project sign-off from stakeholders. You can even share your design with potential customers to really test the viability of your design.
    "
    pages={[
      "A classic landing page mockup",
      "Onboarding flow mockup for your messaging app",
      "Direct messaging and call functionality mockup",
      "Notification screen mockup",
    ]}
    projectCode="aBjXdKqanoSMqgYb876o"
    img1={data.image1.childImageSharp}
    img1alt="team messaging web app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="team messaging app design template message screen"
    img3={data.image3.childImageSharp}
    img3alt="team messaging app design template onboarding screen"
    img4={data.image4.childImageSharp}
    img4alt="team messaging app design template call screen"
    img5={data.image5.childImageSharp}
    img5alt="team messaging app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/team-messaging-web-app/team-messaging-web-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/team-messaging-web-app/team-messaging-web-app-message.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/team-messaging-web-app/team-messaging-web-app-onboarding.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/team-messaging-web-app/team-messaging-web-app-call.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/team-messaging-web-app/team-messaging-web-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
